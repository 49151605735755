import React from 'react'
import { ImageSourcePropType } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { PrimaryButton } from 'APP/Components/Buttons'
import Typography from 'APP/Converse/Typography'
import Shimmer from 'APP/Components/Shimmer'

const OuterContainer = styled.TouchableOpacity<{
  dimmed?: boolean
}>`
  background-color: ${({ theme, dimmed }) =>
    dimmed ? theme.colors.contrastElementBg : theme.colors.elementsBg};
  border-radius: 10px;
  margin-vertical: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  ${({ theme }) => theme.shadows.soft};
  flex-direction: column;
  width: 300px;
`

const ContentContainer = styled.View`
  flex-direction: row;
  gap: 8px;
`

const TextContainer = styled.View`
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  gap: 8px;
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.fonts.fontTypes.header};
  font-size: ${({ theme }) => theme.fonts.fontSize.h4}px;
`

const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.fonts.fontTypes.base};
`

const Image = styled.Image`
  width: ${({ theme }) => theme.metrics.icon.medium}px;
  height: ${({ theme }) => theme.metrics.icon.medium}px;
`

const ActionIconContainer = styled.View`
  justify-content: center;
`

const CTAIconContainer = styled.View`
  justify-content: stretch;
  display: flex;
`

const FullWidthShimmer = styled(Shimmer)`
  width: 100%;
`

const DescriptionIcon = ({
  iconSrc,
  iconName,
  disabled,
}: {
  iconSrc?: ImageSourcePropType
  iconName?: string
  disabled?: boolean
}) => {
  const { colors } = useTheme()
  const colour = disabled ? colors.disabled : colors.accent

  if (iconSrc) return <Image resizeMode="contain" tintColor={colour} source={iconSrc} />
  else if (iconName) return <MaterialCommunityIcon name={iconName} size={20} color={colour} />
}

const ActionIcon = ({ disabled }: { disabled?: boolean }) => {
  const { colors } = useTheme()
  const colour = disabled ? colors.disabled : colors.accent

  return (
    <ActionIconContainer>
      <MaterialCommunityIcon name={'chevron-right'} size={20} color={colour} />
    </ActionIconContainer>
  )
}

const Card = ({
  title,
  subtitle,
  ctaTitle,
  iconSrc,
  iconName,
  isLoading,
  onPress,
  testID = 'CARD',
}: {
  title: string
  subtitle: string
  ctaTitle: string
  iconSrc: ImageSourcePropType
  iconName: string
  isLoading: boolean
  onPress: () => void
  testID: string
}) => {
  const isActionClickable = onPress != null
  const isDimmedTile = isLoading || !isActionClickable
  const isCTATile = ctaTitle != null

  return (
    <OuterContainer
      disabled={isLoading || isCTATile || !isActionClickable}
      dimmed={isDimmedTile}
      testID={testID}
      onPress={!isCTATile ? undefined : onPress}
    >
      <ContentContainer>
        {<DescriptionIcon iconSrc={iconSrc} iconName={iconName} disabled={isLoading} />}

        <TextContainer>
          {isLoading ? <FullWidthShimmer /> : <Title>{title}</Title>}
          {isLoading ? <FullWidthShimmer /> : <Subtitle>{subtitle}</Subtitle>}
        </TextContainer>

        {!isCTATile && isActionClickable && <ActionIcon disabled={isLoading} />}
      </ContentContainer>

      {isCTATile && isActionClickable && !isLoading && (
        <CTAIconContainer>
          <PrimaryButton
            title={ctaTitle}
            onPress={onPress}
            analyticsName="CARD_CTA_TITLE"
            testID={`${testID}_CTA_BUTTON`}
          />
        </CTAIconContainer>
      )}
    </OuterContainer>
  )
}

export default Card
