import React, { useContext, useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import RNFS from 'react-native-fs'
import FileViewer from 'react-native-file-viewer'
import * as Sentry from '@sentry/react-native'
import styled, { useTheme } from 'styled-components/native'
import { useActionSheet } from '@expo/react-native-action-sheet'
import Mattermost from 'APP/Services/Mattermost'
import I18n from 'APP/Services/i18n'
import Alert from 'APP/Converse/Alert'
import Analytics from 'APP/Services/Analytics'
import { ChatContext, PostContext } from 'APP/Lib/Context'
import Card from 'APP/Components/FelixChat/Card'
import { isWeb } from 'APP/Helpers/checkPlatform'
import FileViewerModal from 'APP/Components/FelixChat/FileViewerModal'
import { logDdError } from 'APP/Lib/Datadog'
import Icon from 'APP/Converse/Icon'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'

const ImageContainer = styled.TouchableOpacity`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.practitionerMsgBg};
`

const ImagePreviewContainer = styled.Image`
  border-radius: 10px;
  width: 240px;
  height: 300px;
`

const ActivityIndicatorContainer = styled.ActivityIndicator`
  border-radius: 10px;
  width: 240px;
  height: 300px;
`

const FailedIconContainer = styled.View`
  align-items: center;
  justify-content: center;
  display: flex;
  width: 240px;
  height: 300px;
`

const CardContainer = styled.View`
  flex: 1;
  align-items: ${({ alignRight }) => (alignRight ? 'flex-end' : 'flex-start')};
`

const File = () => {
  const validFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'pdf']
  const validImageTypes = ['jpg', 'jpeg', 'png', 'gif']

  const theme = useTheme()
  const { post, isMember } = useContext(PostContext)
  const { channelId } = useContext(ChatContext)

  const dispatch = useDispatch()
  const { showActionSheetWithOptions } = useActionSheet()

  const { accessToken } = useSelector((state) => state.login)
  const [fileMetadata, setFileMetadata] = useState()
  const [fileSource, setFileSource] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const fileId = post.file_ids?.[0]
  const [isOpenedModal, setIsOpenedModal] = useState(false)

  const postFailed = post?.props?.failed

  useEffect(() => {
    if (fileId) {
      const mattermost = Mattermost.create(accessToken)
      mattermost
        .getPostFileInfo({ postId: post.id })
        .then((response) => setFileMetadata(response[0]))

      mattermost.getPublicUrl({ fileId }).then((url) => setFileSource(url))
    }
  }, [post, accessToken, fileId])

  const handleCloseModal = () => {
    setIsOpenedModal(false)
  }

  const handleLongPress = () => {
    const options = [I18n.t('ConversationScreen.actionSheets.cancel')]

    showActionSheetWithOptions(
      {
        options,
      },
      () => {
        Analytics.trackEvent('button_click', { button_value: 'Cancel send file' })
        dispatch(PatientHistoryActions.removePendingPost(channelId, post.id))
      }
    )
  }

  const handleLongPressError = () => {
    const options = [
      I18n.t('ConversationScreen.actionSheets.retry'),
      I18n.t('ConversationScreen.actionSheets.cancel'),
    ]

    showActionSheetWithOptions(
      {
        options,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          Analytics.trackEvent('button_click', { button_value: 'Retry send file' })
          dispatch(PatientHistoryActions.retryPostRequest(channelId, post.id))
        } else {
          Analytics.trackEvent('button_click', { button_value: 'Cancel send file' })
          dispatch(PatientHistoryActions.removePendingPost(channelId, post.id))
        }
      }
    )
  }

  const viewFileWeb = () => {
    if (fileMetadata?.extension === 'pdf') {
      window.open(fileSource)
    } else {
      setIsOpenedModal(true)
    }
  }

  const viewFileMobile = () => {
    setIsLoading(true)
    const documentsDir = `${RNFS.DocumentDirectoryPath}/Documents`

    const localFile = `${documentsDir}/${fileMetadata.name}`

    const options = {
      fromUrl: fileSource,
      toFile: localFile,
      headers: { cookie: `MMAUTHTOKEN=${accessToken}` },
    }

    const handleError = (error) => {
      setIsLoading(false)
      Alert.alert('', String(error), [{ text: 'OK' }])
    }

    RNFS.mkdir(documentsDir) // make sure documents directory exists
      .then(() => RNFS.downloadFile(options).promise)
      .then(() => {
        FileViewer.open(localFile, {
          onDismiss: () => RNFS.unlink(localFile),
        }).catch(handleError)
        setIsLoading(false)
      })
      .catch(handleError)
  }

  const viewFile = () => {
    try {
      if (!fileSource) return
      Analytics.trackEvent('button_click', { button_value: 'Attachment tap' })

      if (isWeb()) {
        viewFileWeb()
      } else {
        viewFileMobile()
      }
    } catch (err) {
      Sentry.captureException(err)
      logDdError(err.message, err.stack)
    }
  }

  if (!fileMetadata) {
    return (
      <ImageContainer
        testID="LONG_PRESS_VIEW"
        onLongPress={postFailed ? handleLongPressError : handleLongPress}
      >
        {postFailed ? (
          <FailedIconContainer testID="RETRY_STATE">
            <Icon src="reload" variant="material" color="text" />
          </FailedIconContainer>
        ) : (
          <ActivityIndicatorContainer
            testID="LOADING_STATE"
            size="large"
            color={theme.colors.text}
          />
        )}
      </ImageContainer>
    )
  }

  if (fileMetadata && !validFileTypes.includes(fileMetadata.extension)) {
    return (
      <Card
        testID="INVALID_FILE"
        title={I18n.t('ConversationScreen.restricted.main')}
        subtitle={I18n.t('ConversationScreen.restricted.subText')}
        iconName="information"
      />
    )
  }

  if (fileMetadata?.extension === 'pdf') {
    // If the PDF is sent from the user, we don't want the fullscreen actioncard
    return (
      <CardContainer alignRight={isMember}>
        <Card
          testID="PDF_FILE"
          title={fileMetadata.name}
          subtitle={I18n.t('ConversationScreen.file', {
            extension: fileMetadata.extension.toLocaleUpperCase(),
          })}
          onPress={viewFile}
          iconName="download"
          isLoading={isLoading}
        />
      </CardContainer>
    )
  }

  if (
    (fileMetadata && validImageTypes.includes(fileMetadata.extension)) ||
    /\.(jpg|jpeg|png|gif)$/i.test(post.localUrl)
  ) {
    return (
      <>
        <ImageContainer testID="IMAGE" onPress={viewFile}>
          {isLoading ? (
            <ActivityIndicatorContainer size="large" color={theme.colors.text} />
          ) : (
            <ImagePreviewContainer
              source={{ uri: fileSource }}
              resizeMode="cover"
              dataSet={{ ddPrivacy: 'hidden' }}
            />
          )}
        </ImageContainer>
        {isWeb() && (
          <FileViewerModal
            src={fileSource}
            isOpened={isOpenedModal}
            handleCloseModal={handleCloseModal}
          />
        )}
      </>
    )
  }
}

export default memo(File)
