import ColorLibrary from 'APP/Themes/ColorLibrary.json'
import { DefaultTheme } from 'styled-components/native'
import { baseTheme, baseColors } from './themeUtils'

export const diaLight: DefaultTheme = {
  name: 'Dia Light',
  ...baseTheme,
  colors: baseColors,
}

export const diaDark: DefaultTheme = {
  name: 'Dia Dark',
  ...baseTheme,
  colors: {
    ...baseColors,
    background: ColorLibrary.charcoal,
    text: ColorLibrary.linen,
    primary: ColorLibrary.linen,
    primaryText: ColorLibrary.charcoal,
    secondary: ColorLibrary.linen,
    gradientStart: ColorLibrary.peach,
    gradientEnd: ColorLibrary.sky,
    accent: ColorLibrary.peach,
    elementsBg: ColorLibrary.darkSlate,
    elementsBorder: ColorLibrary.dimGray,
    contrastElementBg: ColorLibrary.dimGray,
    tagBg: ColorLibrary.dirtyLinen,
    dim: ColorLibrary.linen,
    practitionerMsgBg: ColorLibrary.darkSlate,
    todayHeaderActionIcon: ColorLibrary.silver,
    clientCardBg: ColorLibrary.darkSlate,
    serviceIconPrimary: ColorLibrary.linen,
  },
}

export const canadalifeLight: DefaultTheme = {
  name: 'Canada Life Light',
  ...baseTheme,
  colors: {
    ...baseColors,
    primary: ColorLibrary.firebrick,
    primaryText: ColorLibrary.linen,
    secondary: ColorLibrary.charcoal,
    gradientStart: ColorLibrary.firebrick,
    accent: ColorLibrary.firebrick,
    gradientEnd: ColorLibrary.snow,
    patientMsgBg: ColorLibrary.blush,
    clientCardBg: ColorLibrary.blush,
    serviceIconPrimary: ColorLibrary.firebrick,
    serviceIconAccent: ColorLibrary.firebrick,
  },
}

export const canadalifeDark: DefaultTheme = {
  name: 'Canada Life Dark',
  ...baseTheme,
  colors: {
    ...baseColors,
    background: ColorLibrary.charcoal,
    text: ColorLibrary.linen,
    primary: ColorLibrary.crimson,
    primaryText: ColorLibrary.charcoal,
    secondary: ColorLibrary.linen,
    gradientStart: ColorLibrary.firebrick,
    gradientEnd: ColorLibrary.snow,
    accent: ColorLibrary.crimson,
    elementsBg: ColorLibrary.darkSlate,
    elementsBorder: ColorLibrary.dimGray,
    contrastElementBg: ColorLibrary.dimGray,
    tagBg: ColorLibrary.dirtyLinen,
    dim: ColorLibrary.linen,
    patientMsgBg: ColorLibrary.blush,
    practitionerMsgBg: ColorLibrary.darkSlate,
    todayHeaderActionIcon: ColorLibrary.silver,
    clientCardBg: ColorLibrary.darkSlate,
    serviceIconPrimary: ColorLibrary.white,
    serviceIconAccent: ColorLibrary.white,
  },
}

export const sunlifeLight: DefaultTheme = {
  name: 'Sun Life Light',
  ...baseTheme,
  colors: {
    ...baseColors,
    primary: ColorLibrary.navyBlue,
    primaryText: ColorLibrary.linen,
    secondary: ColorLibrary.charcoal,
    gradientStart: ColorLibrary.teal,
    gradientEnd: ColorLibrary.sky,
    accent: ColorLibrary.navyBlue,
    patientMsgBg: ColorLibrary.lightBlue,
    clientCardBg: ColorLibrary.lightBlue,
    serviceIconPrimary: ColorLibrary.navyBlue,
    serviceIconAccent: ColorLibrary.navyBlue,
  },
}

export const sunlifeDark: DefaultTheme = {
  name: 'Sun Life Dark',
  ...baseTheme,
  colors: {
    ...baseColors,
    background: ColorLibrary.charcoal,
    text: ColorLibrary.linen,
    primary: ColorLibrary.teal,
    primaryText: ColorLibrary.charcoal,
    secondary: ColorLibrary.linen,
    gradientStart: ColorLibrary.teal,
    gradientEnd: ColorLibrary.sky,
    accent: ColorLibrary.teal,
    elementsBg: ColorLibrary.darkSlate,
    elementsBorder: ColorLibrary.dimGray,
    contrastElementBg: ColorLibrary.dimGray,
    tagBg: ColorLibrary.dirtyLinen,
    dim: ColorLibrary.linen,
    patientMsgBg: ColorLibrary.lightBlue,
    practitionerMsgBg: ColorLibrary.darkSlate,
    todayHeaderActionIcon: ColorLibrary.silver,
    clientCardBg: ColorLibrary.darkSlate,
    serviceIconPrimary: ColorLibrary.teal,
    serviceIconAccent: ColorLibrary.teal,
  },
}

//-- Rebrand Sunlife theme
export const sunlifeLight_v2: DefaultTheme = {
  name: 'Sun Life Light v2',
  ...baseTheme,
  fonts:{
    ...baseTheme.fonts,
    textTransform: {
      button: 'capitalize',
    },
  },
  colors: {
    ...baseColors,
    primary: ColorLibrary.britishRacingGreen,
    secondary: ColorLibrary.charcoal,
    background: ColorLibrary.white,
    gradientStart: ColorLibrary.britishRacingGreen,
    gradientEnd: ColorLibrary.britishRacingGreen,
    accent: ColorLibrary.britishRacingGreen,
    clientCardBg: ColorLibrary.steel,
    patientMsgBg: ColorLibrary.steel,
    serviceIconPrimary: ColorLibrary.charcoal,
    serviceIconAccent: ColorLibrary.charcoal,
  },
}

export const sunlifeDark_v2: DefaultTheme = {
  name: 'Sun Life Dark v2',
  ...baseTheme,
  fonts: {
    ...baseTheme.fonts,
    textTransform: {
      button: 'capitalize',
    },
  },
  colors: {
    ...baseColors,
    background: ColorLibrary.charcoal,
    text: ColorLibrary.linen,
    primary: ColorLibrary.cornSilk,
    primaryText: ColorLibrary.charcoal,
    secondary: ColorLibrary.linen,
    gradientStart: ColorLibrary.cornSilk,
    gradientEnd: ColorLibrary.cornSilk,
    accent: ColorLibrary.cornSilk,
    elementsBg: ColorLibrary.darkSlate,
    elementsBorder: ColorLibrary.dimGray,
    contrastElementBg: ColorLibrary.dimGray,
    tagBg: ColorLibrary.dirtyLinen,
    dim: ColorLibrary.linen,
    patientMsgBg: ColorLibrary.steel,
    practitionerMsgBg: ColorLibrary.darkSlate,
    todayHeaderActionIcon: ColorLibrary.silver,
    clientCardBg: ColorLibrary.darkSlate,
    serviceIconPrimary: ColorLibrary.cornSilk,
    serviceIconAccent: ColorLibrary.cornSilk,
  },
}
//---

export const sunlifeusLight: DefaultTheme = {
  name: 'Sun Life US Light',
  ...baseTheme,
  colors: {
    ...baseColors,
    primary: ColorLibrary.sunLifeBlue,
    primaryText: ColorLibrary.linen,
    secondary: ColorLibrary.charcoal,
    gradientStart: ColorLibrary.sunLifeBlue,
    accent: ColorLibrary.sunLifeBlue,
    gradientEnd: ColorLibrary.snow,
    patientMsgBg: ColorLibrary.lightBlue,
    clientCardBg: ColorLibrary.lightBlue,
    serviceIconPrimary: ColorLibrary.sunLifeBlue,
    serviceIconAccent: ColorLibrary.sunLifeBlue,
  },
}

export const sunlifeusDark: DefaultTheme = {
  name: 'Sun Life US Dark',
  ...baseTheme,
  colors: {
    ...baseColors,
    text: ColorLibrary.linen,
    background: ColorLibrary.charcoal,
    primary: ColorLibrary.sunLifeYellow,
    primaryText: ColorLibrary.sunLifeBlue,
    secondary: ColorLibrary.sunLifeYellow,
    gradientStart: ColorLibrary.sunLifeYellow,
    gradientEnd: ColorLibrary.snow,
    accent: ColorLibrary.sunLifeYellow,
    elementsBg: ColorLibrary.darkSlate,
    elementsBorder: ColorLibrary.dimGray,
    contrastElementBg: ColorLibrary.dimGray,
    tagBg: ColorLibrary.dirtyLinen,
    dim: ColorLibrary.linen,
    patientMsgBg: ColorLibrary.lightBlue,
    practitionerMsgBg: ColorLibrary.darkSlate,
    todayHeaderActionIcon: ColorLibrary.silver,
    clientCardBg: ColorLibrary.darkSlate,
    serviceIconPrimary: ColorLibrary.sunLifeYellow,
    serviceIconAccent: ColorLibrary.sunLifeYellow,
  },
}

export const howdenLight: DefaultTheme = {
  name: 'howden Light',
  ...baseTheme,
  colors: {
    ...baseColors,
    primary: ColorLibrary.mcKenzie,
    accent: ColorLibrary.mcKenzie,
    serviceIconPrimary: ColorLibrary.mcKenzie,
    serviceIconAccent: ColorLibrary.mcKenzie,
  },
}

export const howdenDark: DefaultTheme = {
  name: 'howden Dark',
  ...baseTheme,
  colors: {
    ...baseColors,
    background: ColorLibrary.charcoal,
    text: ColorLibrary.linen,
    primary: ColorLibrary.husk,
    primaryText: ColorLibrary.charcoal,
    secondary: ColorLibrary.linen,
    gradientStart: ColorLibrary.peach,
    gradientEnd: ColorLibrary.sky,
    accent: ColorLibrary.husk,
    elementsBg: ColorLibrary.darkSlate,
    elementsBorder: ColorLibrary.dimGray,
    contrastElementBg: ColorLibrary.dimGray,
    tagBg: ColorLibrary.dirtyLinen,
    dim: ColorLibrary.linen,
    practitionerMsgBg: ColorLibrary.darkSlate,
    todayHeaderActionIcon: ColorLibrary.silver,
    clientCardBg: ColorLibrary.darkSlate,
    serviceIconPrimary: ColorLibrary.husk,
    serviceIconAccent: ColorLibrary.husk,
  },
}

export const ccqLight: DefaultTheme = {
  name: 'ccq Light',
  ...baseTheme,
  colors: {
    ...baseColors,
    primary: ColorLibrary.raspberry,
    accent: ColorLibrary.raspberry,
    gradientStart: ColorLibrary.raspberryLight,
    gradientEnd: ColorLibrary.tealLight,
    serviceIconPrimary: ColorLibrary.raspberry,
    serviceIconAccent: ColorLibrary.raspberry,
  },
}

export const ccqDark: DefaultTheme = {
  name: 'ccq Dark',
  ...baseTheme,
  colors: {
    ...baseColors,
    background: ColorLibrary.charcoal,
    text: ColorLibrary.linen,
    primary: ColorLibrary.ccqTeal,
    primaryText: ColorLibrary.charcoal,
    secondary: ColorLibrary.linen,
    gradientStart: ColorLibrary.tealLight,
    gradientEnd: ColorLibrary.raspberryLight,
    accent: ColorLibrary.ccqTeal,
    elementsBg: ColorLibrary.darkSlate,
    elementsBorder: ColorLibrary.dimGray,
    contrastElementBg: ColorLibrary.dimGray,
    tagBg: ColorLibrary.dirtyLinen,
    dim: ColorLibrary.linen,
    practitionerMsgBg: ColorLibrary.darkSlate,
    todayHeaderActionIcon: ColorLibrary.silver,
    clientCardBg: ColorLibrary.darkSlate,
    darkText: ColorLibrary.charcoal,
    serviceIconPrimary: ColorLibrary.ccqTeal,
    serviceIconAccent: ColorLibrary.ccqTeal,
  },
}
