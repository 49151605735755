import React, { useContext, memo } from 'react'
import { Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { Styles } from './style'

import I18n from 'APP/Services/i18n'
import { ChatContext, PostContext } from 'APP/Lib/Context'
import { BodyPartSelector } from 'APP/Components/LegacyBodyPartSelector'
import Analytics from 'APP/Services/Analytics'
import { channelToTimeStampForNewPost } from 'APP/Lib/MessagingHelpers'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import TextMessage from 'APP/Components/FelixChat/TextMessage'
import Card from 'APP/Components/FelixChat/Card'
import { useDispatch } from 'react-redux'

import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'

import { Images } from 'APP/Themes'

const BodyPart = () => {
  const { channelId, userId } = useContext(ChatContext)
  const { post, isMember, isLastPost } = useContext(PostContext)
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const { allow_none, max_selection_count, min_selection_count } = post.props.config || {}

  const { selectedValues, question_id, resource_id, question = {} } = post.props
  const BodyPartProps = {
    allow_none,
    question_id,
    selectedValues,
    question,
    question_type: question.type,
    resourceId: resource_id,
    maxSelectionCount: max_selection_count,
    minSelectionCount: min_selection_count,
    lang: I18n.baseLocale,
  }
  const navigateToBodyPart = (props = {}) => {
    Analytics.trackEvent('button_click', { button_value: 'Navigate to body part selector' })
    navigation.navigate('bodyPart', {
      submit: (values) => sendBodyPartAnswer(values, props),
      ...props,
    })
  }

  //TODO uses props coming from provider instead of props here (this below code is not working)
  const sendBodyPartAnswer = (selectedValues = [], props) => {
    const replyProps = props.question.reply_props ? props.question.reply_props : {}
    const flags = {
      ...replyProps,
      type: 'bodyPartSelector.v1',
      selectedValues,
      resource_id: props.resourceId,
    }
    let message = selectedValues.join(', ')

    if (!selectedValues.length) {
      // 'N' is the expected region for none being selected
      selectedValues.push('N')
      message = I18n.t('BodyPart.actions.no')
      delete flags.type
    }
    const answer = {
      [props.question_id]: JSON.stringify(selectedValues),
    }
    const payload = `/answer_question${JSON.stringify(answer)}`

    const timeStamp = channelToTimeStampForNewPost(props.conversation)
    dispatch(
      PatientHistoryActions.submitAnswerPostRequest(
        channelId,
        userId,
        {
          question_type: props.question_type,
          text: message,
          data: [payload],
        },
        timeStamp,
        flags
      )
    )

    dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
  }
  if (!isMember && isLastPost) {
    if (!BodyPartProps.allow_none) {
      return (
        <View style={Styles.container}>
          <TextMessage post={post} isMember={false} />
          <Card
            title={I18n.t('BodyPart.title')}
            icon={Images.bodyIcon}
            onPress={() => navigateToBodyPart(BodyPartProps)}
          />
        </View>
      )
    } else {
      const actionNo = I18n.t('BodyPart.actions.no')
      const actionYes = I18n.t('BodyPart.actions.yes')
      return (
        <View>
          <View style={Styles.bubble}>
            <Text style={Styles.bubbleText}>{post.message}</Text>
          </View>
          <View style={Styles.bubbleOptions}>
            <DialogueTouchableOpacity
              style={Styles.button}
              onPress={() => navigateToBodyPart(BodyPartProps)}
              analyticsName="Yes"
            >
              <Text style={Styles.buttonText}>{actionYes}</Text>
            </DialogueTouchableOpacity>
            <DialogueTouchableOpacity
              style={Styles.button}
              onPress={() => sendBodyPartAnswer([], BodyPartProps)}
              analyticsName="No"
            >
              <Text style={Styles.buttonText}>{actionNo}</Text>
            </DialogueTouchableOpacity>
          </View>
        </View>
      )
    }
  }
  if (isMember) {
    return (
      <View style={Styles.bodyPartAnswer}>
        <BodyPartSelector imageScale={0.9} {...BodyPartProps} readOnly pointerEvents="none" />
      </View>
    )
  }
  return null
}

export default memo(BodyPart)
