import React, { useContext, memo } from 'react'
import PatientActions from 'APP/Redux/PatientRedux'

import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import { PostContext } from 'APP/Lib/Context'
import { useDispatch } from 'react-redux'
import Card from 'APP/Components/FelixChat/Card'
import Alert from 'APP/Converse/Alert'

const Charge = () => {
  const { post } = useContext(PostContext)
  const dispatch = useDispatch()

  const onPress = () => {
    Analytics.trackEvent('button_click', { button_value: 'Payment tap' })
    const charge = post.actionContext
    const chargeId = charge.href.split('/charges/')[1]
    const chargeAmount = charge.amount
    const chargeDescription = charge.description

    Alert.alert(
      I18n.t('PaymentScreen.alerts.charge.title'),
      I18n.t('PaymentScreen.alerts.charge.body', {
        amount: chargeAmount,
        description: chargeDescription,
      }),
      [
        { text: I18n.t('PaymentScreen.alerts.charge.decline') },
        {
          text: I18n.t('PaymentScreen.alerts.charge.accept'),
          onPress: () => dispatch(PatientActions.patientCreditCardCharge({ chargeId })),
        },
      ]
    )
  }

  return (
    <Card
      title={I18n.t('ConversationScreen.charge.body')}
      subtitle={post.actionContext.description}
      ctaTitle={I18n.t('ConversationScreen.charge.button', {
        amount: '$' + post.actionContext.amount,
      })}
      iconName="credit-card-outline"
      onPress={onPress}
    />
  )
}

export default memo(Charge)
