import Config from 'APP/Config'
import ColorLibrary from 'APP/Themes/ColorLibrary.json'
import { getStatusBarHeight } from 'react-native-iphone-x-helper'
import Constants from 'expo-constants'
import { Platform } from 'react-native'
import { css } from 'styled-components/native'

const type = Config.FONTS

const statusBarHeight = Platform.OS === 'ios' ? getStatusBarHeight() + 5 : Constants.statusBarHeight

const fontTypes = {
  base: type.base,
  bold: type.bold,
  button: type.button,
  header: type.header,
  light: type.light,
}

const textTransform = {
  button: 'uppercase',
}

const fontSize = {
  h1: 30,
  h2: 24,
  h3: 20,
  h4: 16,

  large: 18,
  regular: 14,

  small: 12,
  captionBold: 11,
  caption: 10,
}

const lineHeight = {
  h1: 36,
  h2: 29,
  h3: 24,
  h4: 19,

  large: 20,
  regular: 16,
  small: 14,
  tinyBold: 13,
  tiny: 12,
}

const softShadow = css`
  shadow-color: #000000;
  shadow-offset: 0px 1px;
  shadow-opacity: 0.06;
  shadow-radius: 4px;
  elevation: 2;
`

const borderShadow = css`
  shadow-color: #000000;
  shadow-offset: 0px 0px;
  shadow-opacity: 0.2;
  shadow-radius: 1px;
`

const normalShadow = css`
  shadow-color: #000000;
  shadow-offset: 0px 3px;
  shadow-opacity: 0.16;
  shadow-radius: 5px;
  elevation: 2;
`

export const shadows = {
  soft: softShadow,
  normal: normalShadow,
  border: borderShadow,
}

const borderRadius = {
  small: 8,
  medium: 20,
  large: 30,
}

const metrics = {
  baseMargin: 16,
  // TODO: we should operate by components and avoid create constants for each component in theme
  button: {
    width: 200,
    height: 48,
    small: 40,
  },
  icon: {
    tiny: 15,
    xsmall: 20,
    small: 24,
    medium: 36,
    large: 48,
    xlarge: 56,
  },
  borderRadius: { ...borderRadius },
  statusBarHeight,
}

export const baseColors = {
  text: ColorLibrary.charcoal,
  darkText: ColorLibrary.charcoal,
  background: ColorLibrary.snow,
  lightGray: ColorLibrary.smoke,
  white: ColorLibrary.white,
  error: ColorLibrary.alert,
  success: ColorLibrary.peppermint,
  dim: ColorLibrary.dimGray,
  disabled: ColorLibrary.silver,
  primary: ColorLibrary.charcoal,
  primaryText: ColorLibrary.linen,
  primaryTextDisabled: ColorLibrary.darkSlate,
  secondary: ColorLibrary.charcoal,
  gradientStart: ColorLibrary.peach,
  gradientEnd: ColorLibrary.sky,
  accent: ColorLibrary.pumpkin,
  separateLine: ColorLibrary.smoke,
  elementsBg: ColorLibrary.white,
  elementsBorder: ColorLibrary.smoke,
  contrastElementBg: ColorLibrary.smoke,
  switchTrackColor: ColorLibrary.silver,
  tagBg: ColorLibrary.sky,
  patientMsgBg: ColorLibrary.linen,
  practitionerMsgBg: ColorLibrary.smoke,
  todayHeaderActionIcon: ColorLibrary.dimGray,
  clientCardBg: ColorLibrary.linen,
  serviceIconPrimary: ColorLibrary.charcoal,
  serviceIconAccent: ColorLibrary.pumpkin,
  videoBg: ColorLibrary.charcoal,
}

const fonts = {
  baseColors,
  fontTypes,
  fontSize,
  lineHeight,
  textTransform,
}

export const baseTheme = {
  fonts,
  metrics,
  shadows,
  isSmallScreen: false,
  isBigScreen: false,
}
