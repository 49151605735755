import React, { useContext, useEffect, useState, memo } from 'react'
import { Linking, View } from 'react-native'
import * as AddCalendarEvent from 'react-native-add-calendar-event'
import * as Sentry from '@sentry/react-native'
import Alert from 'APP/Converse/Alert'

import Card from 'APP/Components/FelixChat/Card'
import TextMessage from 'APP/Components/FelixChat/TextMessage'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { parseIcsUrl } from 'APP/Helpers/IcsUtils'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'

import { PostContext } from 'APP/Lib/Context'
import { Styles } from './style'
import { logDdError } from 'APP/Lib/Datadog'

import { checkCalendarState, requestCalendarPermission } from 'APP/NativeModules/PermissionsService'

const AddToCalendar = () => {
  const { post } = useContext(PostContext)
  const [permissions, setPermissions] = useState('')

  useEffect(() => {
    if (isMobile()) {
      checkCalendarState().then((res) => setPermissions(res))
    }
  }, [])

  const onPress = (choice) => {
    Analytics.trackEvent('button_click', { button_value: 'Add to calendar' })
    try {
      const onError = () => {
        Alert.alert(I18n.t('CannotAddToCalendar.title'), I18n.t('CannotAddToCalendar.body'), [
          { text: I18n.t('CannotAddToCalendar.ok') },
        ])
      }
      // Download the ics file if its the web app
      if (isWeb()) {
        Linking.openURL(choice.link).catch(onError)
      } else {
        const apptInfo = parseIcsUrl(choice.link)
        const eventConfig = {
          title: apptInfo.title,
          startDate: apptInfo.startDate,
          endDate: apptInfo.endDate,
          notes: apptInfo.description,
        }
        if (permissions === 'granted') {
          AddCalendarEvent.presentEventCreatingDialog(eventConfig).catch(onError)
        } else {
          requestCalendarPermission().then(() => {
            // if null or denied, will prompt for permission
            // if blocked, will show error
            AddCalendarEvent.presentEventCreatingDialog(eventConfig).catch(onError)
          })
        }
      }
    } catch (err) {
      Sentry.captureException(err)
      logDdError(err.message, err.stack)
    }
  }

  return (
    <View style={Styles.container}>
      <TextMessage post={post} isMember={false} />
      <View>
        {post.props.choices
          .filter((choice) => choice.link !== undefined)
          .map((choice) => {
            return (
              <Card
                key={choice.id}
                title={choice.display}
                iconName="calendar-blank"
                onPress={() => onPress(choice)}
                testID="CALENDAR_CARD"
              />
            )
          })}
      </View>
    </View>
  )
}

export default memo(AddToCalendar)
